import React, { useEffect } from "react";
import mainLogo from "../../assets/images/recop-logo.png";
import { NavLink } from "react-router-dom";
import $ from "jquery";

export default function MainAppbar(): React.ReactElement {
  useEffect(() => {
    function handleScroll() {
      if ($(window).scrollTop() > 50) {
        $('header').addClass('sticky');
      } else {
        $('header').removeClass('sticky');
      }
    }

    $(window).on('scroll', handleScroll);

    return () => {
      $(window).off('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="main-header position-fixed w-100">
      <div className="container">
        <nav className="navbar navbar-expand-xl py-0">
          <div className="logo">
            <a className="navbar-brand py-0 me-0" href="#_">
              <img style={{ height: "65px" }} src={mainLogo} alt="" />
            </a>
          </div>
          <a
            className="d-inline-block d-lg-block d-xl-none d-xxl-none  nav-toggler text-decoration-none"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <i className="ti ti-menu-2 text-warning"></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <NavLink to="/home" className="nav-link text-capitalize">
                  Início
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/home" className="nav-link text-capitalize">
                  Concursos públicos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/home" className="nav-link text-capitalize">
                  Análise de concursos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/home" className="nav-link text-capitalize">
                  Suporte
                </NavLink>
              </li>
            </ul>
            <div className="d-flex align-items-center">
              <NavLink
                to="/home"
                style={{ backgroundColor: "#333333" }}
                className="btn btn-light btn-hover-secondery me-2"
              >
                Registe-se
              </NavLink>
              <NavLink
                to="/home"
                className="btn btn-light btn-hover-secondery"
                style={{ backgroundColor: "#333333" }}
              >
                Recop Empresas
              </NavLink>
            </div>
          </div>
        </nav>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <div className="logo">
            <a className="navbar-brand py-0 me-0" href="#_">
              <img src={mainLogo} alt="" />
            </a>
          </div>
          <button
            type="button"
            className="btn-close text-reset  ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="ti ti-x text-warning"></i>
          </button>
        </div>
        <div className="offcanvas-body pt-0">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link text-capitalize"
                aria-current="page"
                href="#_"
              >
                Concursos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-capitalize" href="#_">
                Relatórios
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-capitalize" href="#_">
                Serviços{" "}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-capitalize" href="#_">
                Suporte{" "}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-capitalize" href="#_">
                Sobre Nós
              </a>
            </li>
          </ul>
          <div className="login d-block align-items-center mt-3">
            <a className="btn btn-warning text-capitalize w-100" href="#_">
              Língua
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
