import HeaderLayout from "../../layout/headbarLayout";
import SimpleLayout from "../../layout/simpleLayout";
import { RoutesPropTypes } from "../../types/common";
import Home from "../../pages/home/Home"
import { Navigate } from "react-router-dom";

export const routes = () => {
    return [
        {
            path: "/",
            layout: SimpleLayout,
            component: () => <Navigate to="/home" replace />
        },
        {
            path: "/home",
            layout: HeaderLayout,
            component: () => <Home />
        }
    ]
}