import { NavLink } from "react-router-dom";
import rightImage from "../../assets/images/hero/right-image.svg";

const MainCover = () => {
  return (
    <div>
      <section className="hero-banner position-relative overflow-hidden">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="position-relative left-hero-color">
                <h1 className="mb-0 fw-bold">
                  Repositório de Concursos Públicos
                </h1>
                <p>Mais de mil concursos públicos registados.</p>
                <div>
                  <NavLink
                    to="/home"
                    style={{ backgroundColor: "#568C3E" }}
                    className="btn btn-warning btn-hover-secondery me-2"
                  >
                    Análise de concursos
                  </NavLink>
                  <NavLink
                    to="/home"
                    style={{ backgroundColor: "#568C3E" }}
                    className="btn btn-warning btn-hover-secondery me-2"
                  >
                    Concursos públicos
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="position-relative right-hero-color">
                <img src={rightImage} alt="Recop" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainCover;
