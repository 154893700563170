import chartsScreen from "../../assets/images/portfolio/Portfolio.jpg";

const PortifolioSection = () => {
  return (
    <div>    
    {/* <!------------------------------>
    <!-- Portfolio section Start---->
    <!------------------------------> */}
    <section className="portfolio position-relative bg-primary">
        <div className="container position-relative">
            <div className="row">
                <div className="col-12 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-block d-block align-items-center justify-content-xxl-between justify-content-xl-between justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-sm-center ">
                    <h2 className="fs-3 text-white mb-0">Insights importantes sobre<br/> os concursos públicos em Moçambique</h2>
                </div>
            </div>
            <div className="row d-flex flex-wrap justify-content-center step-row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
                    <div className="card bg-transparent">
                        <div className="card-body">
                            <div className="icon mx-auto rounded-circle d-flex justify-content-center align-items-center bg-transparent border"><i className="ti ti-chart-pie text-white"></i></div>
                            <h3 className="fs-4 text-white">Estatísticas gerais</h3>
                            <p className="fs-7 mb-0 fw-500 text-white">Um conjunto de gráficos que permitem uma análise geral sobre a situação dos concursos públicos.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
                    <div className="card bg-transparent">
                        <div className="card-body">
                            <div className="icon  mx-auto rounded-circle d-flex justify-content-center align-items-center bg-transparent border"><i className="ti ti-chart-infographic text-white"></i></div>
                            <h3 className="fs-4 text-white">Estatísticas Mensais</h3>
                            <p className="fs-7 mb-0 fw-500 text-white">Um conjunto de gráficos que permitem ter detalhes mensais dos concursos públicos</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
                    <div className="card bg-transparent">
                        <div className="card-body">
                            <div className="icon mx-auto rounded-circle d-flex justify-content-center align-items-center bg-transparent border"><i className="ti ti-businessplan text-white"></i></div>
                            <h3 className="fs-4 text-white">Estatisticas Financeiras</h3>
                            <p className="fs-7 mb-0 fw-500 text-white">Avaliação do desenvolvimento financeiro das empresas. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid px-5">
            <div className="d-flex">
                <div className="item"><a href="#_"><img src={chartsScreen} alt="Portifófio" style={{width: 500}} /></a></div>
                <div className="item"><a href="#_"><img src={chartsScreen} alt="Portifófio" style={{width: 500}} /></a></div>
                <div className="item"><a href="#_"><img src={chartsScreen} alt="Portifófio" style={{width: 500}} /></a></div>
            </div>
        </div>    
    </section>
{/*     <!------------------------------>
    <!-- Portfolio section End ----->
    <!------------------------------> */}
    
    </div>
   
  );
};

export default PortifolioSection;
