import mainLogo from "../../assets/images/recop-logo.png";

const Footer = () => {
  return (
    <div>
    {/* <!------------------------------>
    <!-----Footer Start------------->
    <!------------------------------> */}
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="footer-logo-col">
                        <a href="#_"><img src={mainLogo} alt="Recop" style={{width: 100}} /></a>
                        <p className="blue-light mb-0 fs-7 fw-500">Recop is a simple, elegant, and secure way to build your bitcoin and crypto portfolio.</p>
                        
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                    <h5 className="text-white">Social</h5>
                    <ul className="list-unstyled mb-0 pl-0">
                        <li><a href="#_">Blog</a></li>
                        <li><a href="#_">Facebook</a></li>
                        <li><a href="#_">Twitter</a></li>
                        <li><a href="#_">Instagram</a></li>
                    </ul>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12">
                    <h5 className="text-white">Company</h5>
                    <ul className="list-unstyled mb-0 pl-0">
                        <li><a href="#_" >About</a></li>
                        <li><a href="#_">Affiliates</a></li>
                        <li><a href="#_">Careers</a></li>
                        <li><a href="#_">Legal & Privacy</a></li>
                    </ul>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="subscribe">
                        <h5 className="text-white">Subscribe</h5>
                        <p className="blue-light fw-500">Subscribe to get the latest news form us
                        </p>
                        <div className="input-group">
                            <input type="email" className="form-control br-15" placeholder="Enter email address" aria-label="Enter email address" aria-describedby="button-addon2" />
                            <button className="btn btn-warning btn-hover-secondery ms-xxl-2 ms-xl-2 ls-lg-0 ms-md-0 ms-sm-0 ms-0"  type="button" id="button-addon2">Register</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyrights text-center blue-light  fw-500">@<span id="autodate">2023</span> - Todos direitos reservados <a href="https://adminmart.com/" className="blue-light text-decoration-none">.</a> Desenvolvido por <a href="https://themewagon.com" className="text-primary text-decoration-none">BCC Experts.</a></div>
        </div>
    </footer>
    {/* <!------------------------------>
    <!-------Footer End------------->
    <!------------------------------> */}
    
    </div>
   
  );
};

export default Footer;
