import rightImage from "../../assets/images/hero/right-image.svg";
import dotShape from "../../assets/images/service/dot-shape.png";
import ourServices from "../../assets/images/our-service/our-service.svg";

const PricingSection = () => {
  return (
    <div>
      {/*     <!------------------------------>
    <!-- Pricing section Start------>
    <!------------------------------> */}
      <section className="pricing position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <small className="fs-7 d-block">Preçário</small>
              <h2 className="fs-3 pricing-head text-black mb-0 position-relative">
                Nossos planos e preços de subscrição
              </h2>
            </div>
          </div>
          <div className="row plans">
            <div className="col-12 text-center">
              <div className="form-check form-switch d-flex justify-content-center ps-0 align-items-center">
                <label
                  className="form-check-label text-black fs-7"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Mensal
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked
                />
                <label
                  className="form-check-label text-black fs-7"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Anual
                </label>
              </div>
              <div className="save d-inline-block position-relative text-warning fw-500 fs-9 text-center">
                Poupe até 37%
              </div>
            </div>
          </div>
          <div className="row justify-content-center price-plan">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="card position-relative shadow-sm border h-100">
                <div className="card-body pb-4">
                  <small className="fs-7 d-block text-warning text-center">
                    Individual
                  </small>
                  <h2 className="mb-4 text-center position-relative">
                    <sub className="fs-2 text-black">0</sub>
                    <sup className="fs-6 position-absolute">Mt</sup>
                  </h2>
                  <small className="fs-7 d-block text-center">Grátis</small>
                  <p className="fs-7 text-center fw-500">Para pessoas</p>
                  <ul className="list-unstyled mb-0 pl-0">
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 100 dados
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 10 gráficos
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 5 mapas densidade
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="card-action text-center pb-xxl-5 pb-xl-5 pb-lg-5 pb-md-4 pb-sm-4 pb-4">
                  <a
                    href="#_"
                    className="btn btn-warning btn-hover-secondery text-capitalize"
                  >
                    Aderir
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="card position-relative shadow-sm border h-100">
                <div className="position-absolute badge bg-warning d-inline-block mx-auto">
                  Mais Popular
                </div>
                <div className="card-body pb-4">
                  <small className="fs-7 d-block text-warning text-center">
                    Profissional
                  </small>
                  <h2 className="mb-4 text-center position-relative">
                    <sub className="fs-2 text-black">1500</sub>
                    <sup className="fs-6 position-absolute">Mt</sup>
                  </h2>
                  <small className="fs-7 d-block text-center">Subscrição</small>
                  <p className="fs-7 text-center fw-500">Para profissionais </p>
                  <ul className="list-unstyled mb-0 pl-0">
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 4000 dados
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 30 gráficos
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 10 mapas densidade
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a análises anuais
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="card-action text-center pb-xxl-5 pb-xl-5 pb-lg-5 pb-md-4 pb-sm-4 pb-4">
                  <a
                    href="#_"
                    className="btn btn-warning btn-hover-secondery text-capitalize"
                  >
                    Aderir
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="card position-relative shadow-sm border h-100">
                <div className="card-body pb-4">
                  <small className="fs-7 d-block text-warning text-center">
                    Empresarial
                  </small>
                  <h2 className="mb-4 text-center position-relative">
                    <sub className="fs-2 text-black">2500</sub>
                    <sup className="fs-6 position-absolute">Mt</sup>
                  </h2>
                  <small className="fs-7 d-block text-center">Subscrição</small>
                  <p className="fs-7 text-center fw-500">Para empresas</p>
                  <ul className="list-unstyled mb-0 pl-0">
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 8000 dados
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 30 gráficos
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a 20 mapas densidade
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="ti ti-circle-check fs-4 pe-2"></i>
                      <span className="fs-7 text-black">
                        Acesso a análises anuais, trimestrais e mensais
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="card-action text-center pb-xxl-5 pb-xl-5 pb-lg-5 pb-md-4 pb-sm-4 pb-4">
                  <a
                    href="#_"
                    className="btn btn-warning btn-hover-secondery text-capitalize"
                  >
                    Aderir
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------------------------------>
    <!-- Pricing section End-------->
    <!------------------------------> */}
    </div>
  );
};

export default PricingSection;
