import { FC, ReactElement } from "react";
import { LayotProps } from "../../types/common";
import styles from "../../assets/styles/Layout.module.scss";
import MainAppbar from "../../components/layout/MainAppbar";

const HeaderLayout: FC<LayotProps> = ({ children }): ReactElement => {
  return (
    <div className={styles["layout-container"]}>
      <MainAppbar />
      <main className={styles["main-content__container"]}>{children}</main>
    </div>
  );
};
export default HeaderLayout;
