import rightImage from "../../assets/images/hero/right-image.svg";
import ourServices from "../../assets/images/our-service/our-service.svg";
import Footer from "../../components/sections/Footer";
import MainCover from "../../components/sections/MainCover";
import OurServiceSection from "../../components/sections/OurService";
import PortifolioSection from "../../components/sections/Portifolio";
import PricingSection from "../../components/sections/Pricing";
import ServicesSection from "../../components/sections/Services";

const Home = () => {
  return (
    <div>
        <MainCover />    
        <ServicesSection />    
        <OurServiceSection />    
        <PortifolioSection />    
        <PricingSection />    
        <Footer />    
    </div>
   
  );
};

export default Home;
