import rightImage from "../../assets/images/hero/right-image.svg";
import dotShape from "../../assets/images/service/dot-shape.png";
import ourServices from "../../assets/images/our-service/our-service.svg";
import { NavLink } from "react-router-dom";

const ServicesSection = () => {
  return (
    <div>
      {/* <!------------------------------>
    <!--- Service section Start------>
    <!------------------------------> */}
      <section className="service position-relative overflow-hidden">
        <div className="container position-relative">
          <img
            src={dotShape}
            alt="Análise de dados"
            className="shape position-absolute"
          />
          <div className="row">
            <div className="col-12">
              <small className="fs-7 d-block">Nosso trabalho</small>
            </div>
            <div className="col-12 d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-block d-block align-items-center justify-content-xxl-between justify-content-xl-between justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-sm-center ">
              <h2 className="fs-2 text-black mb-0">
                Os serviços que<br /> oferecemos
                 em destaque
              </h2>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-center step-row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
              <div className="card border shadow-sm">
                <div className="card-body">
                  <div className="icon-round overflow-hidden rounded-circle position-relative d-flex align-items-center justify-content-center mx-auto text-center">
                    <i className="ti ti-chart-dots text-primary position-relative"></i>
                  </div>
                  <h3 className="fs-4">Análise de Concursos</h3>
                  <p className="fs-7 mb-0 fw-500">
                    Tenha acesso a uma análise de mais de 8000 dados registados
                    nesta plataforma.
                  </p>
                  <NavLink
                    to="/home"
                    style={{ backgroundColor: "#568C3E" }}
                    className="btn btn-warning btn-hover-secondery mt-3 px-4"
                  >
                    Ver insights
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
              <div className="card border shadow-sm">
                <div className="card-body">
                  <div className="icon-round overflow-hidden rounded-circle position-relative d-flex align-items-center justify-content-center mx-auto text-center">
                    <i className="ti ti-chart-candle text-primary position-relative"></i>
                  </div>
                  <h3 className="fs-4">Concursos Públicos</h3>
                  <p className="fs-7 mb-0 fw-500">
                    Tenha acesso digital a todos concursos lançados a nível
                    nacional.
                  </p>
                  <NavLink
                    to="/home"
                    style={{ backgroundColor: "#568C3E" }}
                    className="btn btn-warning btn-hover-secondery mt-3 px-4"
                  >
                    Ver concursos
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-center">
              <div className="card border shadow-sm">
                <div className="card-body">
                  <div className="icon-round overflow-hidden rounded-circle position-relative d-flex align-items-center justify-content-center mx-auto text-center">
                    <i className="ti ti-chart-circles text-primary position-relative"></i>
                  </div>
                  <h3 className="fs-4">Consultoria</h3>
                  <p className="fs-7 mb-0 fw-500">
                    Tenha acesso a uma consultoria com uma equipe altamente
                    qualificada.
                  </p>
                  <NavLink
                    to="/home"
                    style={{ backgroundColor: "#568C3E" }}
                    className="btn btn-warning btn-hover-secondery mt-3 px-4"
                  >
                    Solicitar
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------------------------------>
    <!--- Service section Start------>
    <!------------------------------> */}
    </div>
  );
};

export default ServicesSection;
