import rightImage from "../../assets/images/hero/right-image.svg";
import dotShape from "../../assets/images/service/dot-shape.png";
import ourServices from "../../assets/images/our-service/our-service1.svg";

const OurServiceSection = () => {
  return (
    <div>
    {/* <!---------------------------------->
    <!--- Our Service section Start------>
    <!----------------------------------> */}
    <section className="our-service position-relative overflow-hidden">
        <div className="container">
            <div className="row">
                <div className="col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <img src={ourServices} alt="Nossos serviços" className="img-fluid" /> 
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ps-xxl-0 ps-xl-0 ps-lg-3 ps-md-3 ps-sm-3 ps-3">
                    <small className="fs-7 d-block">Sobre o Recop</small>
                    <h2 className="fs-2 text-black mb-0">Repositório de concursos públicos</h2>
                    <p className="mb-0 fw-500 fs-7">
                        Seamlessly see the tasks that need your attention, check when your next meeting is coming up, and keep up with your progress.
                    </p>
                    <ul className="list-unstyled mb-0 pl-0">
                        <li className="d-flex flex-wrap align-items-start">
                            <i className="ti ti-circle-check fs-4 pe-2"></i>
                            <span className="fs-7 text-black">List of the tasks that require your attention.</span>
                        </li>
                        <li className="d-flex flex-wrap align-items-start">
                            <i className="ti ti-circle-check fs-4 pe-2"></i>
                            <span className="fs-7 text-black">Google Calendar integration to see when interruptions will happen</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section> 
    {/* <!------------------------------>
    <!--- Our Service section End---->
    <!------------------------------> */}
    
    </div>
   
  );
};

export default OurServiceSection;
