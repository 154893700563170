import { HashRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes';

const Router = () => {
    return (
        <HashRouter>
            <Routes>
                {
                    routes().map((route, index) => {
                        const Layout = route.layout;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        {route.component()}
                                    </Layout>
                                }
                            />
                        )
                    })
                }
            </Routes>
        </HashRouter>
    )
}
export default Router