import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './assets/styles/style.css';
import './assets/styles/iconfont/tabler-icons.scss';
import "bootstrap/dist/js/bootstrap.min.js";
import App from './components/app/App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
